import { Icon } from "@components/Elements/Buttons/styled";
import React from "react";
import { CellTextStyled } from "./styled";

export default function CellText({
  className = "",
  color = "light",
  weight = 400,
  startIcon = null,
  endIcon = null,
  ...props
}) {
  return (
    <CellTextStyled
      color={color}
      weight={weight}
      className={`${className}${startIcon || endIcon ? " cell-icon" : ""}`}
      {...props}
    >
      {startIcon && <Icon $startIcon={!!startIcon}>{startIcon}</Icon>}
      {props.children}
      {endIcon && <Icon $endIcon={!!endIcon}>{endIcon}</Icon>}
    </CellTextStyled>
  );
}
