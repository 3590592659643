import { REGEX_NUMBER_ONLY } from "@constants";
import {
  BC_FUNCTIONS,
  DEFAULT_TIMEZONE,
  FORMATTING_TYPES,
  NOT_AVAILABLE_TEXT,
} from "@constants/index";
import { customMessage } from "@constants/utils";
import {
  CONSENSUS_THRESHOLD,
  PAIR_LOCKED_FACTORY,
  POOL_STATUS,
  PRIORITY_PERIODS,
  STAKING_DAY_IN_SECONDS,
  STAKING_FACTORY_CONTRACT,
  VOTE_FACTORY,
  VOTE_STAKING_FACTORY,
  VOTE_TYPE,
} from "@pages/trade/AdminCommunity/Staking/constants";
import store from "@redux/index";
import {
  fetchToken,
  prepareWriteContract,
  readContract,
  writeContract,
} from "@wagmi/core";
import { message } from "antd";
import Decimal from "decimal.js";
import { ethers } from "ethers";
import i18n from "i18next";
import { capitalize, find, isNull, map, omitBy, pickBy } from "lodash";
import moment from "moment";
import momentTz from "moment-timezone";
import numeral from "numeral";
import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { ContractFunctionRevertedError, formatUnits } from "viem";
import { waitForTransaction } from "wagmi/actions";
import { LoadingRef } from "..";
import snackbarUtils from "./snackbar-utils";
import clientUtils from "./client-utils";

export function formatPrice(x) {
  if (x === null || x === undefined || x === "") {
    return null;
  } else if (x === 0) {
    return 0;
  } else {
    x = x.toString();
    x = Math.round(x * 1e8) / 1e8;
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
}

export const formatterNumber = (val) => {
  if (!val) return null;
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const convertDate = (date) => {
  // convert from "DD-MM-YYYY HH:mm:ss" to YYYY-MM-DDTHH:mm:ss"
  const arrayDate = date.split(" ");
  const newDay = arrayDate[0].split("-").reverse().join("-");
  const newDate = [newDay, arrayDate[1]].join("T");
  return newDate;
};

export const applyDecimals = (rawValue, decimals, sign = "negative") => {
  if (!rawValue) return "";

  return Decimal(rawValue)
    .mul(Decimal(10).pow(Decimal(sign === "positive" ? decimals : -decimals)))
    .toFixed();
};

export const copyToClipBoard = async (
  text = "",
  notiText = "Copy successfully!"
) => {
  await navigator.clipboard.writeText(text);
  message.success(notiText);
};

export function mobileCheck() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

export function timeConverter(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp * 1000);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  // var month = months[a.getMonth()];
  var month = a.getMonth() + 1;
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time =
    date + "/" + month + "/" + year + "-" + hour + ":" + min + ":" + sec;
  return time;
}

Number.prototype.formatViews = function () {
  if (this < 1000) {
    return this;
  }
  if (this >= 1000 && this < 1000000) {
    return (this / 1000).toFixed(1) + "K";
  }
  if (this >= 1000000 && this < 1000000000) {
    return (this / 1000000).toFixed(1) + "M";
  }
  if (this >= 1000000000) {
    return (this / 1000000000).toFixed(1) + "B";
  }
};

export const getFilePdf = async ({ pdf }) => {
  let data;
  if (pdf) {
    await pdf
      .then(async (Response) => {
        await Response.arrayBuffer()
          .then((s) => (data = s))
          .catch(() => null);
      })
      .catch((e) => console.log("pdf", e));
  }
  return data;
};
export function parseParamUrls() {
  const obj = {};
  const part = (window.location.search || "?").slice(1).split("&");
  for (let group of part) {
    let pair = group.split("=");
    obj[pair[0]] = pair[1];
  }
  return obj;
}
export function combineUrlParams(url = "", params = {}) {
  const keys = Object.keys(params);
  const paramUrl = keys
    .reduce((result, key) => {
      if (Array.isArray(params[key]) && params[key]?.length > 0) {
        return [...result, `${key}=${params[key]}`];
      } else if (
        !Array.isArray(params[key]) &&
        (params[key] ||
          params[key] === 0 ||
          typeof params[key] === "boolean") &&
        typeof params[key] !== "object"
      ) {
        return [...result, `${key}=${params[key]}`];
      } else if (
        typeof params[key] === "object" &&
        params[key] !== null &&
        params[key] !== {}
      ) {
        let multipleParams = Object.entries(params[key])
          ?.filter(
            ([key, value]) =>
              value != null &&
              value != undefined &&
              key != null &&
              key != undefined
          )
          ?.map((item) => {
            let [key, value] = item;
            return `${key},${value}`;
          })
          ?.map((item) => `${key}=${item}`);
        return [...result, ...multipleParams];
      } else {
        return [...result];
      }
      // else if()
      // return (
      //   Array.isArray(params[key])
      //     ? params[key]?.length > 0
      //     : params[key] ||
      //       params[key] === 0 ||
      //       typeof params[key] === "boolean"
      // )
      //   ? [...result, `${key}=${params[key]}`]
      //   : [...result];
    }, [])
    .join("&");
  return `${url}?${paramUrl}`;
}
export const nonAccentVietnamese = (str = "") => {
  if (!str) return "";
  str = str.toString().toLowerCase();
  //     We can also use this instead of from line 11 to line 17
  //     str = str.replace(/\u00E0|\u00E1|\u1EA1|\u1EA3|\u00E3|\u00E2|\u1EA7|\u1EA5|\u1EAD|\u1EA9|\u1EAB|\u0103|\u1EB1|\u1EAF|\u1EB7|\u1EB3|\u1EB5/g, "a");
  //     str = str.replace(/\u00E8|\u00E9|\u1EB9|\u1EBB|\u1EBD|\u00EA|\u1EC1|\u1EBF|\u1EC7|\u1EC3|\u1EC5/g, "e");
  //     str = str.replace(/\u00EC|\u00ED|\u1ECB|\u1EC9|\u0129/g, "i");
  //     str = str.replace(/\u00F2|\u00F3|\u1ECD|\u1ECF|\u00F5|\u00F4|\u1ED3|\u1ED1|\u1ED9|\u1ED5|\u1ED7|\u01A1|\u1EDD|\u1EDB|\u1EE3|\u1EDF|\u1EE1/g, "o");
  //     str = str.replace(/\u00F9|\u00FA|\u1EE5|\u1EE7|\u0169|\u01B0|\u1EEB|\u1EE9|\u1EF1|\u1EED|\u1EEF/g, "u");
  //     str = str.replace(/\u1EF3|\u00FD|\u1EF5|\u1EF7|\u1EF9/g, "y");
  //     str = str.replace(/\u0111/g, "d");
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
  return str;
};

export const nonAccentVietnameseKeepCase = (str = "") => {
  if (!str) return "";
  str = str.toString();
  //     We can also use this instead of from line 11 to line 17
  //     str = str.replace(/\u00E0|\u00E1|\u1EA1|\u1EA3|\u00E3|\u00E2|\u1EA7|\u1EA5|\u1EAD|\u1EA9|\u1EAB|\u0103|\u1EB1|\u1EAF|\u1EB7|\u1EB3|\u1EB5/g, "a");
  //     str = str.replace(/\u00E8|\u00E9|\u1EB9|\u1EBB|\u1EBD|\u00EA|\u1EC1|\u1EBF|\u1EC7|\u1EC3|\u1EC5/g, "e");
  //     str = str.replace(/\u00EC|\u00ED|\u1ECB|\u1EC9|\u0129/g, "i");
  //     str = str.replace(/\u00F2|\u00F3|\u1ECD|\u1ECF|\u00F5|\u00F4|\u1ED3|\u1ED1|\u1ED9|\u1ED5|\u1ED7|\u01A1|\u1EDD|\u1EDB|\u1EE3|\u1EDF|\u1EE1/g, "o");
  //     str = str.replace(/\u00F9|\u00FA|\u1EE5|\u1EE7|\u0169|\u01B0|\u1EEB|\u1EE9|\u1EF1|\u1EED|\u1EEF/g, "u");
  //     str = str.replace(/\u1EF3|\u00FD|\u1EF5|\u1EF7|\u1EF9/g, "y");
  //     str = str.replace(/\u0111/g, "d");
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ữ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
  return str;
};
String.prototype.formatPathFromTitle = function () {
  return this.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "$")
    .split(" ")
    .map((item) => item.toLowerCase())
    .join("-");
};

export const getLengthAddress = (address, screen) => {
  return screen > 992
    ? `${address?.substring(0, 5)} ... ${address?.substring(
        address?.length - 5,
        address?.length
      )} `
    : screen > 576
    ? `${address?.substring(0, 5)} ... ${address?.substring(
        address?.length - 5,
        address?.length
      )} `
    : `${address?.substring(0, 5)} ... ${address?.substring(
        address?.length - 5,
        address?.length
      )} `;
};

export const getLengthAddressByWindowScreen = (address, screenWidth) => {
  return screenWidth > 1300
    ? address?.length > 20
      ? `${address?.substring(0, 10)} ... ${address?.substring(
          address?.length - 10,
          address?.length
        )} `
      : address
    : screenWidth > 1100
    ? address?.length > 14
      ? `${address?.substring(0, 7)} ... ${address?.substring(
          address?.length - 7,
          address?.length
        )} `
      : address
    : address?.length > 10
    ? `${address?.substring(0, 5)} ... ${address?.substring(
        address?.length - 5,
        address?.length
      )} `
    : address;
};

export const getBackGroundFromScreen = (scrWidth) => {
  if (scrWidth >= 1400) return 1920;
  if (scrWidth >= 1200 && scrWidth < 1400) return 1400;
  if (scrWidth >= 992 && scrWidth < 1200) return 1200;
  if (scrWidth >= 768 && scrWidth < 992) return 992;
  if (scrWidth >= 576 && scrWidth < 768) return 768;
  return 576;
};
export const x10_18 = (value) => {
  return ethers.utils.parseUnits(
    typeof value == "string" ? value : value.toString(),
    18
  );
};
export const toDecimal = (amount) => {
  return amount ? ethers.utils.formatUnits(amount, "ether") : 0;
};

export const toNumber = (amount) => {
  return amount ? ethers.utils.formatUnits(amount, 0) : 0;
};
export const parseEther = (amount) => {
  if (typeof amount === "number") {
    amount = amount.toString();
  }
  return ethers.utils.parseEther(amount);
};

export const toBigNumber = (amount) => {
  return ethers.BigNumber.from(amount.toString());
};

export const replaceSpecialCharacters = (value) => {
  // const specialChars = /[^a-zA-Z0-9 ]/g;
  const specialChars = /[!@#$%^&*()"":,.`~]/g;

  return value.replace(specialChars, "");
};

export const checkContainSpecialCharacters = (value) => {
  const specialChars = /[!@#$%^&*()"":,.`~]/g;

  return specialChars.test(value);
};

export const blockChainConfirmationTransaction = async ({
  transaction = async () => {},
  callback = () => {},
  successCallback = () => {},
  failCallback = () => {},
  successText = "Transaction success!",
  duration = 2 * 60 * 1000, //60 seconds
}) => {
  LoadingRef.current.show();
  let timeout;
  try {
    timeout = setTimeout(() => {
      LoadingRef.current.hide();
      snackbarUtils.error(strings("message.transactionTimeout"));
      return;
    }, duration);
    let res1 = await transaction();
    if (res1.wait) await res1.wait();
    clearTimeout(timeout);
    successCallback();
    snackbarUtils.success(successText);
  } catch (error) {
    clearTimeout(timeout);

    failCallback();
    let primitiveText = error?.reason || error?.message;
    let failText =
      customMessage.find((item) =>
        item.requireMessage
          .map((item) => item.toLowerCase())
          .find((customText) =>
            primitiveText.toLowerCase().includes(customText)
          )
      )?.customMessage || primitiveText;
    snackbarUtils.error(failText);
  } finally {
    callback();
    LoadingRef.current.hide();
  }
};

export const strings = (name, params = {}) => {
  return i18n.t(name, params);
};

export const formatDate = (
  str,
  pattern = "DD/MM/YYYY",
  timezone = DEFAULT_TIMEZONE
) => {
  if (!str) return "";
  let newMoment = moment(str);
  if (!newMoment.isValid()) return "";
  const convertedTime = momentTz.tz(str, timezone);
  const formattedTime = convertedTime.format(pattern);
  return formattedTime;
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const convertUrlPacs = (path) => {
  const splitUrl = path.split(".");
  // let provider = "";
  if ("dcm" === splitUrl[splitUrl.length - 1]) {
    // provider = "wadouri:";
    return (
      "wadouri:" +
      API_PACS_IMAGE +
      Buffer.from(path, "utf-8").toString("base64")
    );
  }
  return (
    // provider +
    clientUtils.serverApi +
    "/api/pacs/file?path=" +
    Buffer.from(path, "utf-8").toString("base64")
  );
};

export const getNotiContent = ({
  dataName = "",
  buyerAddress = "",
  price = "",
  type,
}) => {
  switch (type) {
    case 10:
      return strings("noti.someoneSentAnRequestAccessOn").replace(
        "{dataName}",
        dataName
      );
    case 20:
      return strings("noti.yourDataHasBeenReportedByOthersAboutFraud").replace(
        "{buyerAddress}",
        buyerAddress
      );
    case 30:
      return strings(
        "noti.yourRequestToAccessToHasBeenRejectedByTheOwner"
      ).replace("{dataName}", dataName);
    case 40:
      return strings(
        "noti.yourRequestToAccessToHasBeenAcceptedByTheOwner"
      ).replace("{dataName}", dataName);
    case 50:
      return strings("noti.hasBeenReportedSuccessfully").replace(
        "{dataName}",
        dataName
      );
    case 60:
      return strings("noti.weFoundNoIssueOnTheYouReported").replace(
        "{dataName}",
        dataName
      );
    case 70:
      return strings(
        "noti.IVIHasBeenDepositedIntoSmartContractBalance"
      ).replace("{price}", price);
    case 80:
      return strings(
        "noti.IVIHasBeenWithdrawnFromSmartContractBalance"
      ).replace("{price}", price);
    case 90:
      return strings(
        "noti.yourDataHasBeenListedOnMarketplaceSuccessfully"
      ).replace("{dataName}", dataName);
    default:
      return strings("noti.youHaveNewNotifications");
  }
};

export const getNotiTitle = (type) => {
  switch (type) {
    case 10:
      return strings("noti.newRequestAccess");
    case 20:
      return strings("noti.reportFeedback");
    case 30:
      return strings("noti.requestRejected");
    case 40:
      return strings("noti.requestAccepted");
    case 50:
      return strings("noti.reportFeedback");
    case 60:
      return strings("noti.reportFeedback");
    case 70:
      return strings("noti.depositSuccessfully");
    case 80:
      return strings("noti.withdrawSuccessfully");
    case 90:
      return strings("noti.dataListedOnMarketplaceSuccessfully");
    default:
      return strings("noti.newNotification");
  }
};

export const isExpiredRequest = (req) => {
  return moment.unix(req.createdAt._hex).add(1, "day").isBefore(moment());
};

export const containSpecialCharacter = (value) => {
  return /^[a-zA-Z0-9 àáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđAÀÁẢÃẠĂẰẮẲẴẶÂẦẤẨẪẬEÈÉẺẼẸÊỀẾỂỄỆIÌÍỈĨỊOÒÓỎÕỌƠỜỚỞỠỢÔỒỐỔỖỘUÙÚỦŨỤƯỪỨỬỮỰYỲÝỶỸỴĐ]+$/.test(
    value
  );
};

export const isValidEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validateEmail = (email) => {
  let valid = true;
  let msg = null;

  if (!email) {
    valid = false;
    msg = "Email is required!";
  }
  if (email && !isValidEmail(email || "")) {
    valid = false;
    msg = "Invalid email!";
  }

  return { valid, msg };
};

export const validatePassword = (pwd) => {
  let valid = true;
  let msg = null;

  if (!pwd) {
    valid = false;
    msg = "Password is required!";

    return { valid, msg };
  }
  if (pwd && pwd?.includes(" ")) {
    valid = false;
    msg = "Password cannot contain white space!";

    return { valid, msg };
  }

  if (pwd && pwd?.length < 10) {
    valid = false;
    msg = "Password must be at least 10 characters!";
  }

  return { valid, msg };
};

export const toCapitalize = (str) => {
  if (str?.length <= 1) {
    return str?.toUpperCase();
  }
  let result = str.split(" ");
  result = result.map((item) => {
    if (item?.length <= 1) {
      return item?.toUpperCase();
    }
    let first = item[0].toUpperCase();
    return first + item.slice(1);
  });

  return result.join(" ");
};

export const checkingSearch = (value, keyword) => {
  return value.toLowerCase().includes(keyword.toLowerCase());
};

export function isNumberOnly(value) {
  if (value === "" || REGEX_NUMBER_ONLY.test(value)) return true;
  return false;
}

export async function getTokenInfos(address) {
  if (!address) return {};
  try {
    const token = await fetchToken({
      address,
    });
    return token;
  } catch (error) {
    return {};
  }
}

export function formatNumber({
  number,
  format = FORMATTING_TYPES.NUMBER_6_DECIMAL_DIGITS,
  fallback = NOT_AVAILABLE_TEXT,
  roundingFunction = (v) => v,
  invalidSuffix = "",
}) {
  return numeral(number || 0)
    .format(format, roundingFunction)
    .replace(`NaN${invalidSuffix}`, fallback);
}

export function revertNumberFormatted(str) {
  return numeral(str).value();
}

/**
 * Convert an integer value with decimal precision to its corresponding integer value.
 * @param {number} number - The numeric int value.
 * @param {number} decimal - The decimal precision of the token.
 */
export function getEthereumValue(number, decimal) {
  return (number || 0) / Math.pow(10, decimal);
}

export function objectMap(obj, fn) {
  return Object.fromEntries(
    Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)])
  );
}

export function findTokenInfos(tokens, address) {
  const token = find(
    tokens,
    (token) => token.address.toLowerCase() === address?.toLowerCase()
  );
  return token;
}

export function searchTokens(tokens, keyword, exact = false) {
  const whitelist = ["name", "symbol", "address"];
  const newArray = [];
  for (const element of tokens) {
    for (const field of whitelist) {
      if (
        element[field] && exact
          ? element[field].toLowerCase() === keyword.toLowerCase()
          : element[field].toLowerCase().includes(keyword.toLowerCase())
      ) {
        newArray.push(element);
        break;
      }
    }
  }
  return newArray;
}

export function getErrorMessage(parentKey, error) {
  let childKey;
  if (error === "Network Error") {
    childKey = "NETWORK_ERROR";
  } else {
    childKey = error;
  }
  let errorMessage;

  errorMessage = i18n.t(`${parentKey}.errorMessages.${childKey}`, {
    defaultValue: null,
  });

  if (!errorMessage) {
    errorMessage = i18n.t(`commonErrorMessages.${childKey}`, {
      defaultValue: null,
    });
  }

  if (!errorMessage) {
    errorMessage = i18n.t("commonErrorMessages.UNKNOWN");
  }
  return errorMessage;
}

export function timeInSeconds(time) {
  return Math.floor(new Date(time).getTime() / 1000);
}

export function calculateAPR(stakedValue, rewardedValue) {
  const value = (rewardedValue / stakedValue) * 100;
  return value;
}

export function calculateAPY(apr, periodInDays) {
  const dailyRate = (1 + apr / 100 / periodInDays) ** periodInDays;
  const value = (dailyRate - 1) * 100;
  return value;
}

export function mapPools(pools) {
  const { javaStaking } = store.getState();
  const { tokens } = javaStaking;

  const results = map(pools, (pool) => {
    const stakedTokenInfos = findTokenInfos(tokens, pool.stakedToken);
    const rewardedTokenInfos = findTokenInfos(tokens, pool.rewardedToken);

    const minimumPerPersonNumber = formatUnits(
      pool.minimumPerPerson,
      stakedTokenInfos.decimals
    );
    const maximumPerPersonNumber = formatUnits(
      pool.maximumPerPerson,
      stakedTokenInfos.decimals
    );
    const stakedAmountNumber = formatUnits(
      pool.stakedAmount,
      stakedTokenInfos.decimals
    );
    const rewardedAmountNumber = formatUnits(
      pool.rewardedAmount,
      rewardedTokenInfos.decimals
    );
    const stakingAmount = formatUnits(
      pool.stakingAmount || 0,
      stakedTokenInfos.decimals
    );
    const rewardingAmount = formatUnits(
      pool.rewardingAmount || 0,
      rewardedTokenInfos.decimals
    );
    const claimedAmount = formatUnits(
      pool.claimedAmount || 0,
      rewardedTokenInfos.decimals
    );

    const periodInDays = Math.floor(pool.period / STAKING_DAY_IN_SECONDS);
    const stakedValueUSD = stakedAmountNumber * stakedTokenInfos.price;
    const rewardedValueUSD = rewardedAmountNumber * rewardedTokenInfos.price;
    const stakingAmountUSD = stakingAmount * stakedTokenInfos.price;
    const rewardingAmountUSD = rewardingAmount * rewardedTokenInfos.price;
    const APR = calculateAPR(stakedValueUSD, rewardedValueUSD);
    const APY = calculateAPY(APR, periodInDays);

    const formattedAPR = formatNumber({
      number: APR / 100,
      format: FORMATTING_TYPES.PERCENT_2_DECIMAL_DIGITS,
      invalidSuffix: "%",
    });

    const formattedAPY = formatNumber({
      number: APY / 100,
      format: FORMATTING_TYPES.PERCENT_2_DECIMAL_DIGITS,
      invalidSuffix: "%",
    });

    return {
      ...pool,
      stakingAmount,
      rewardingAmount,
      claimedAmount,
      stakedValue: stakedValueUSD,
      rewardedValue: rewardedValueUSD,
      stakingAmountUSD,
      rewardingAmountUSD,
      period: periodInDays,
      effectiveDate: pool.effectiveDate * 1000,
      endDate: pool.endDate * 1000,
      apr: formattedAPR,
      apy: formattedAPY,
      stakedTokenInfos,
      rewardedTokenInfos,
      minimumPerPerson: Number(minimumPerPersonNumber),
      maximumPerPerson: Number(maximumPerPersonNumber),
      stakedAmount: stakedAmountNumber,
      rewardedAmount: rewardedAmountNumber,
    };
  });
  return results;
}

export function sortedPeriods(periods, key) {
  const priorityItems = [];
  const filteredAndSorted = periods
    .filter((periodItem) => {
      if (PRIORITY_PERIODS.includes(periodItem[key])) {
        priorityItems.push({
          ...periodItem,
        });
        return false;
      }
      return true;
    })
    .sort((a, b) => a[key] - b[key]);
  priorityItems.sort((a, b) => a[key] - b[key]);
  const combinedItems = [...priorityItems, ...filteredAndSorted];
  return combinedItems;
}

export function getStatuses(statuses) {
  const result = map(statuses, (status) => status.toLowerCase()).join(",");
  return result;
}

export async function getContractReadData({
  func,
  address = STAKING_FACTORY_CONTRACT,
  abi = PAIR_LOCKED_FACTORY.abi,
  args,
}) {
  try {
    const data = await readContract({
      args,
      functionName: func,
      address,
      abi,
    });
    return data;
  } catch (error) {
    throw error;
  }
}

export function getRevertErrorMsg(originalMsg) {
  const regexPattern = /(?:PairLockedFactory|VoteFactory):\s(.+)/;
  const match = originalMsg.match(regexPattern);

  if (match && match.length > 1) {
    const extractedString = match[1];
    return capitalize(extractedString);
  } else {
    return null;
  }
}

export async function getVotesStatistics(
  action,
  requestId,
  totalActiveAdmin,
  address
) {
  const commonParams = {
    abi: VOTE_FACTORY.abi,
    address: VOTE_STAKING_FACTORY,
  };
  const acceptVotePromise = getContractReadData({
    func: BC_FUNCTIONS.GET_TOTAL_VOTE,
    args: [action, requestId, VOTE_TYPE.ACCEPT],
    ...commonParams,
  });

  const rejectVotePromise = getContractReadData({
    func: BC_FUNCTIONS.GET_TOTAL_VOTE,
    args: [action, requestId, VOTE_TYPE.REJECT],
    ...commonParams,
  });

  const [totalAcceptVote, totalRejectVote] = await Promise.all([
    acceptVotePromise,
    rejectVotePromise,
  ]);

  const approvedPercent = Number(toNumber(totalAcceptVote)) / totalActiveAdmin;
  const rejectedPercent = Number(toNumber(totalRejectVote)) / totalActiveAdmin;

  const validAcceptConsensus = approvedPercent >= CONSENSUS_THRESHOLD;
  const validRejectConsensus = rejectedPercent >= CONSENSUS_THRESHOLD;

  const accountVoteValue = await getContractReadData({
    func: BC_FUNCTIONS.GET_ACCOUNT_VOTE,
    abi: VOTE_FACTORY.abi,
    address: VOTE_STAKING_FACTORY,
    args: [action, requestId, address],
  });

  return {
    totalAcceptVote,
    totalRejectVote,
    approvedPercent,
    rejectedPercent,
    actions: {
      delete: !validRejectConsensus,
      reject: accountVoteValue === VOTE_TYPE.REJECT,
      approve: accountVoteValue === VOTE_TYPE.ACCEPT,
      release: !validAcceptConsensus,
    },
  };
}

export const votingPoolsCustomData = async (pools) => {
  const { javaStaking, admin, contracts } = store.getState();
  const { contractProperties } = javaStaking;
  const { totalActiveAdmin } = admin;
  const { address } = contracts;

  const promises = map(pools, async (pool) => {
    const { status, requestId } = pool;
    switch (status) {
      case POOL_STATUS.VOTING:
      case POOL_STATUS.REVOKING:
        const action = contractProperties[status.toLowerCase()];
        const { approvedPercent, rejectedPercent, actions } =
          await getVotesStatistics(
            action,
            requestId,
            totalActiveAdmin,
            address
          );
        pool.approvedPercent = approvedPercent;
        pool.rejectedPercent = rejectedPercent;
        pool.actions = actions;
        break;
      case POOL_STATUS.DRAFT:
        pool.actions = {
          delete: false,
          reject: true,
          approve: true,
          release: true,
        };
        break;
      default:
        break;
    }
    return pool;
  });
  const results = await Promise.all(promises);
  return results;
};

/**
 * Prepares and writes a contract to the blockchain.
 * @async
 * @param {Object} options - The options object.
 * @param {string} [options.address=STAKING_FACTORY_CONTRACT] - The contract address.
 * @param {Object} [options.abi=PAIR_LOCKED_FACTORY.abi] - The contract ABI.
 * @param {string} options.functionName - The name of the function to call.
 * @param {Array} [options.args=[]] - The arguments to pass to the function.
 * @param {number} [options.timeout=0] - The timeout in seconds.
 * @param {string} [options.successHeadingText="Transaction success!"] - The success message heading.
 * @param {string} [options.successDescriptionText] - The success message description.
 * @param {Function} [options.successCallback=() => {}] - The success callback function.
 * @returns {Promise<void>}
 */
export async function handlePrepareWriteContract({
  address = STAKING_FACTORY_CONTRACT,
  abi = PAIR_LOCKED_FACTORY.abi,
  functionName,
  args = [],
  timeout = 0,
  successHeadingText = "Transaction success!",
  successDescriptionText,
  btnText,
  customOk,
  callback = () => {},
  hideNotify = false,
}) {
  try {
    LoadingRef.current.show();
    const { request } = await prepareWriteContract({
      address,
      abi,
      args,
      functionName,
    });
    const { hash } = await writeContract(request);

    waitForTransaction({ hash })
      .then(() => {
        setTimeout(async () => {
          await callback({ isSuccess: true });
          !hideNotify &&
            snackbarUtils.success(
              successHeadingText,
              successDescriptionText,
              customOk,
              btnText
            );
          LoadingRef.current.hide();
        }, timeout * 1000);
      })
      .catch(() => {
        LoadingRef.current.hide();
      });
  } catch (error) {
    await callback({ isSuccess: false });
    if (!hideNotify) {
      const revertError =
        typeof error === "object"
          ? error?.shortMessage
          : error?.walk((err) => err instanceof ContractFunctionRevertedError);

      const regex = /(VoteFactory|PairLocked|PairLockedFactory|ERC20):\s*(.*)/;
      const match = revertError.match(regex);
      const message = match ? match[2] : revertError;
      snackbarUtils.error("Failed", capitalize(message));
    }
    LoadingRef.current.hide();
  }
}

export function handleStopPropagation(event) {
  return event.stopPropagation();
}

export function isAddress(value) {
  return ethers.utils.isAddress(value);
}

export function getRowStatusVariants(status) {
  switch (status) {
    case POOL_STATUS.DRAFT:
      return {
        statusText: capitalize(status),
        dotColor: "#EA95BC",
      };
    case POOL_STATUS.VOTING:
    case POOL_STATUS.ACTIVE:
    case POOL_STATUS.REVOKING:
      return {
        statusText: capitalize(status),
        dotColor: "#33E0C2",
      };
    case POOL_STATUS.INACTIVE:
    case POOL_STATUS.REJECTED:
      return {
        statusText: capitalize(status),
        dotColor: "#B0B0B0",
      };
    case POOL_STATUS.COMPLETED:
      return {
        statusText: capitalize(status),
        dotColor: "#56B988",
      };
  }
}

export function getTableEmptyText(loading) {
  return omitBy(
    {
      emptyText: loading ? "Loading..." : null,
    },
    isNull
  );
}

export function getSvgDataURL(SvgComponent) {
  const dataUri =
    "data:image/svg+xml," +
    encodeURIComponent(renderToStaticMarkup(<SvgComponent />));
  return dataUri;
}

export function canRegisterToken() {
  return (
    typeof window !== "undefined" &&
    !window?.ethereum?.isSafePal &&
    (window?.ethereum?.isMetaMask || window?.ethereum?.isTrust)
  );
}

export function getSubscriptionEstInterest({
  amount,
  rewardedAmount,
  stakedAmount,
}) {
  const rewardAmount = (amount * rewardedAmount) / stakedAmount;
  return rewardAmount || 0;
}

export function toFixed(num, fixed) {
  const re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  return num.toString().match(re)[0];
}

export function preventDefault(e) {
  return e.preventDefault();
}

export function canHover() {
  return window.matchMedia("(hover: hover)").matches;
}

export function getTooltipDisableProps(tooltipDisable) {
  return pickBy({ open: !tooltipDisable }, (value) => !value);
}
export const dataURItoBlob = (dataURI) => {
  const byteString = atob(dataURI.split(",")[1]);
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  return new Blob([arrayBuffer], { type: mimeString });
};
